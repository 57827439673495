<template>
  <div class="problem">
      <el-form>
        <el-form-item label="点位：">
          <el-text type="primary">{{props.problem.pointPositionName}}</el-text>
        </el-form-item>
        <el-form-item label="标准：">
          <el-text>{{props.problem.standardName}}</el-text>
        </el-form-item>
        <el-form-item label="标签：">
          <el-tag type="success" v-if="props.problem.labelFirst">{{props.problem.labelFirst}}</el-tag>
          <el-tag type="warning" v-if="props.problem.labelSecond">{{props.problem.labelSecond}}</el-tag>
          <el-tag type="default" v-if="props.problem.labelThird">{{props.problem.labelThird}}</el-tag>
        </el-form-item>
        <el-form-item label="问题时间：" v-if="props.problem.reportTime">
          <el-text>{{props.problem.reportTime}}</el-text>
        </el-form-item>
        <el-form-item label="状态：">
            <template v-for="item in data.problemStatusList">
              <el-tag :type=item.type :key="item.index" v-if="item.value == props.problem.problemStatus">{{ item.label }}</el-tag>
          </template>
        </el-form-item>
        <el-form-item label="是否反复：" v-if="props.problem.hasRepeat">
          <el-text v-if="props.problem.hasRepeat==true" type="primary">是</el-text>
          <el-text v-else type="warning">否</el-text>
        </el-form-item>
        <el-form-item label="问题程度：" v-if="props.problem.problemLevel">
          <el-text>{{props.problem.problemLevel}}</el-text>
        </el-form-item>
        <el-form-item label="问题描述：" v-if="props.problem.problemDescribe">
          <el-text>{{props.problem.problemDescribe}}</el-text>
        </el-form-item>
        <el-form-item label="问题图片" v-if="props.problem.problemPics">
          <el-image
            v-for="pic, index in props.problem.problemPicsArray"
            :key="index"
            style="width: 100px; height: 100px; margin-right: 20px;"
            :src="pic"
            :zoom-rate="1.2"
            :max-scale="7"
            :min-scale="0.2"
            :preview-src-list="props.problem.problemPicsArray"
            :initial-index="index"
            fit="cover"
          />
        </el-form-item>
        <el-form-item label="审核时间：" v-if="props.problem.checkTime">
          <el-text>{{props.problem.checkTime}}</el-text>
        </el-form-item>
        <el-form-item label="整改初审时间：" v-if="props.problem.rectifyFirstCheckTime">
          <el-text>{{props.problem.rectifyFirstCheckTime}}</el-text>
        </el-form-item>
        <el-form-item label="整改终审时间：" v-if="props.problem.rectifyEndCheckTime">
          <el-text>{{props.problem.rectifyEndCheckTime}}</el-text>
        </el-form-item>
        <el-form-item label="分值：" v-if="props.problem.score">
          <el-text type="danger">{{props.problem.score}}分</el-text>
        </el-form-item>
      </el-form>
  </div>
</template>
  
<script setup>
import { defineProps,reactive} from 'vue'

const data = reactive({
  problemStatusList: [
    {
      label: "考核通过",
      value: "checkPass",
      type:"success"
    },
    {
      label: "待审核",
      value: "waitCheck",
      type:"info"
    },
    {
      label: "审核不通过",
      value: "checkUnPass",
      type:"danger"
    },
    {
      label: "待整改",
      value: "waitRectify",
      type:"info"
    },
    {
      label: "整改待初审",
      value: "waitFirstReview",
      type:"primary"
    },
    {
      label: "待终审",
      value: "waitEndReview",
      type:"warning"
    },
    {
      label: "整改通过",
      value: "reviewPass",
      type:"success"
    }
  ]
})


const props = defineProps(['problem'])

</script>